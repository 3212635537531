import { BasicsClass } from '@/common/BasicsClass';



export class quotaManagement extends BasicsClass {
  //获取额度配置列表
  public getwalletList(form: any, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/wallet/page', { ...form, ...page }, false, false, cb);
  }
  //获取额度配置详情
  public getwalletInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/wallet/getInfo', { id }, false, false, cb);
  }
  //新增额度配置
  public addtwallet(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/wallet/save', {...form}, false, false, true, cb);
  }
  //修改额度状态
  public updatewalletStatus(form: any, cb: any): void {
    this.BasicGet('/animal/web/ordering/wallet/updateStatus', {...form}, false, false, cb);
  }
  //修改额度配置
  public updatewallet(form: any, cb: any): void {
    this.BasicPost('/animal/web/ordering/wallet/update', {...form}, false, false, true, cb);
  }
  //获取额度配置门店列表
  public getwalletpageStore(form: any,page: any, cb: Function) {
    this.BasicPost('/animal/web/ordering/wallet/pageStore', { ...form, ...page }, false, false, true,cb);
  }
  

}
