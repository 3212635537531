




import { Component, Vue } from "vue-property-decorator";
@Component
export default class FormBtnReset extends Vue {
    private handlerReset() {
        this.$emit("handlerReset", "");
    }
}
