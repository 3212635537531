import { render, staticRenderFns } from "./PageBox.vue?vue&type=template&id=2aa02dea&scoped=true&"
import script from "./PageBox.vue?vue&type=script&lang=ts&"
export * from "./PageBox.vue?vue&type=script&lang=ts&"
import style0 from "./PageBox.vue?vue&type=style&index=0&id=2aa02dea&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa02dea",
  null
  
)

export default component.exports