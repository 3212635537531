




import { Component, Vue } from "vue-property-decorator";
@Component
export default class FormBtnSearch extends Vue {
    private hanlerSearch() {
        this.$emit("handlerSearch", "");
    }
}
