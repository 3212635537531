


























































































































































































import { Watch, Component, Vue } from "vue-property-decorator";

import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
import { Column } from "@/common/Views/system/column/column";
import { getGroupCodeForUser319, getForGroupCodelistCity } from "@/api/index";
import PageBox from "@/components/PageBox/PageBox.vue";
import FormBtnSearch from "@/components/FormBtnSearch/FormBtnSearch.vue";
import FormBtnReset from "@/components/FormBtnReset/FormBtnReset.vue";
import { quotaManagement } from "@/common/Views/BeefOrdering/QuotaManagement/QuotaManagement";
@Component({
    components: {
        PageBox,
        InputComonentVue,
        FormBtnSearch,
        FormBtnReset,
    },
})
export default class AddQuotaManagement extends Vue {
    private form: any = {
        userId: "", //门店
        quota: "", //额度
        repaymentDate: "", //还款日
    };
    private quota = "";
    private seDisabled = false;
    private updateType = false;
    private rules: any = {
        userId: [
            { required: true, message: "请选择门店", trigger: "change" },
        ],
        quota: [
            {
                required: true,
                message: "请设置额度",
                trigger: "change",
            },
        ],
        repaymentDate: [
            { required: true, message: "请选择还款日", trigger: "change" },
        ],
    };
    private addTime(type: any) {
        this.form.transportTimeListtemp.push({
            dateType: type,
            startTime: "",
            endTime: "",
            selectedTime: [
                new Date(2024, 8, 9, 0, 0),
                new Date(2024, 8, 9, 23, 59),
            ],
        });
    }
    //时间戳转时间
    private formatTimeArray(timeArray: any) {
        return timeArray.map((date: any) => {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? "0" + minutes : minutes;
            return `${hours}:${minutes}`;
        });
    }
    //时间转时间戳
    private formatTime(time: any) {
        let date = new Date(time);
        return date.getTime();
    }
    private dataList = [
        {id:1,time:"每月1号"},
        {id:5,time:"每月5号"},
        {id:10,time:"每月10号"},
        {id:15,time:"每月15号"},
        {id:20,time:"每月20号"},
        {id:25,time:"每月25号"},
    ];
    private changeTime(index: any) {
        
    }
    private treeData = [];
    private propsObj: any = {
        lazy: true,
        expandTrigger: "hover",
        value: "id",
        label: "name",
        lazyLoad(node: any, resolve: any) {
            getGroupCodeForUser319(
                node.value ? node.value : 0,
                (window as any).isInternational,
                (res: any) => {
                    try {
                        let nodes = res.data;
                        nodes.forEach((item: any) => {
                            item.leaf = item.level === 3;
                        });
                        resolve(nodes);
                    } catch (e) {}
                }
            );
        },
    };
    //事件
    private SaveData() {
        //保存
        if (this.userId == "") {
            this.$message({
                type: "warning",
                message: "请选择门店",
            });
            return;
        }else{
            this.form.userId = this.userId;
        }
        if (this.quota == "") {
            this.$message({
                type: "warning",
                message: "请设置额度",
            });
            return;
        }else{
            if(parseInt(this.quota)*100 <= 0){
                this.$message({
                    type: "warning",
                    message: "额度必须大于0",
                });
                return;
            }else{
                this.form.quota = parseInt(this.quota)*100;
            }
        }
        if (this.form.repaymentDate == "") {
            this.$message({
                type: "warning",
                message: "请选择还款日",
            });
            return;
        }
        console.log(this.form, 137);
        this.$refs.form.validate((flag: boolean) => {
            if (flag) {
                this.addtwallet(this.form);
            }
        });
    }

    //emit事件
    private SetquotaValue(val: string) {
        //详细地址
        this.quota = val;
    }
    private SetKeyValue(val: any[]) {
        //key
        this.form.sort = val;
    }
    private addtwallet(form: any) {
        //新增
        new quotaManagement().addtwallet(form, (res: any) => {
            this.$message({
                type: "success",
                message: "授权门店额度成功",
            });
            this.ClearFormData()
            this.$router.push({
                path: "/QuotaManagement",
            });
        });
    }
    private ClearFormData() {
        //清楚Form数据
        this.form = {
            userId: "", //门店
            quota: "", //额度
            repaymentDate: "", //还款日
        };
        this.quota = "";
        this.userId = "";
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 5,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.dialogVisible = false;
    }
    private ClearCheck() {
        //清除校验
        this.$refs.form.clearValidate();
    }
    private listAllOne = [];
    private wu = {
        id: 0,
        categoryName: "无",
    };
    private type = "";
    activated() {
        this.ClearFormData();
        this.ClearCheck();
        this.getList();
    }
    private dialogVisible = false;
    private relatedGoods() {
        //关联商品
        this.dialogVisible = true;
    }
    private searchform: any = {
        keyword: "",
    };
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 5,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private tableData = [];
    private getList() {
        new quotaManagement().getwalletpageStore(
            this.searchform,
            this.tablePage,
            (res: any) => {
                this.tablePage.totalCount = res.page.totalCount;
                this.tableData = res.data;
            }
        );
    }
    private handlerSearch() {
        //搜索
        this.tablePage.pageNo = 1;
        this.getList();
    }
    private handlerReset() {
        //重置
        this.searchform = {
            keyword: "",
        };
        this.getList();
    }
    private userId = "";
    private userName = "";
    private saveOk() {
        //确定
        this.form.userId = this.userId;
        this.form.storeName = this.userName;
        this.dialogVisible = false;
    }
    private confirm() {
        //取消
        this.dialogVisible = false;
    }
    private radioChangeEvent(val: any) {
        //单选框
        console.log(val);

        console.log(val.rowIndex);
        this.userId = val.row.userId;
        this.userName = val.row.storeName;
    }
    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getList();
    }
    private SetInputVal(val: string) {
        console.log(val);
        this.searchform.keyword = val;
    }
}
